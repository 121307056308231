import React from 'react';
import PropTypes from 'prop-types';
import RawHtml from '../RawHtml/RawHtml';
import styles from './ThreeColumnModule.module.scss';

const ThreeColumnModule = ({ title, columns, preamble }) => {
    return (
        <div className={styles['ThreeColumnModule']}>
            <div className={styles['ThreeColumnModule__Container']}>
                <h2 className={styles['ThreeColumnModule__Title']}>{title}</h2>
                {preamble && (
                    <div className={styles['ThreeColumnModule__Preamble']}>
                        <RawHtml html={preamble} />
                    </div>
                )}
                <div className={styles['ThreeColumnModule__Grid']}>
                    {columns &&
                        columns.map((item, index) => (
                            <div
                                key={index}
                                className={styles['ThreeColumnModule__Column']}>
                                <RawHtml html={item.richText} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

ThreeColumnModule.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            html: PropTypes.string,
        })
    ),
};

ThreeColumnModule.defaultProps = {
    title: '',
    columns: [],
};

export default ThreeColumnModule;
